/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body, td { font-family: "Roboto", sans-serif; font-size:20px; color:rgb(64, 64, 64); }

.container {
  max-width:1280px;
}

h2 { font-family: "Nunito Sans", sans-serif; font-size:52px; color:rgb(0, 40, 69); font-weight:bold; position:relative; }
h2:after {
  content: "";
  width: 90px;
  height: 1px;
  background-color: #6ae6aa;
  position: absolute;
  bottom: -10px;
  left: 0px;
}

/* main menu */
.menu-whiterow {
  font-family: "Nunito Sans", sans-serif;
  font-size:16px;
  color:rgb(0, 40, 69);
  height:50px;
  line-height:50px;
}
.menu-logorow {
  background-color: #002845;
  padding-top:10px;
  padding-bottom:10px;
}
.menu-logorow .container img {
  max-width: 100%;
}
.menu-logorow-mobile {
  background-color: #002845;
  padding-top: 15px;
  padding-bottom: 18px;
}
.menu-logorow-mobile img {
  max-width: 100%;
}
.navbar-toggler {
  width:50px;
  height:40px;
  background-image: url("assets/images/menu-opener.png");
  background-repeat: no-repeat;
  border-radius:0;
  margin-top:15px;
}
.navbar {
  padding-left:0;
  border-bottom:1px solid #CCCCCC;
}
.nav-link {
  color:rgb(0, 40, 69) !important;
  font-family: "Nunito Sans", sans-serif;
  font-size:19px;
  font-weight:400;
  border-bottom:2px solid transparent;
  margin-top:5px;
  padding:0 !important;
  margin-left: 5px;
  margin-right: 5px;
}
.nav-link:hover {
  color:rgb(0, 166, 81) !important;
  border-bottom:2px solid #D1D1D1;
}

/* footer */
.footer-container {
  margin-top:50px;
  background-color:#002845;
}
.footer-logorow {
  padding-top:35px;
  /* padding-bottom:38px; */
}
.footer-logorow img {
  max-width:100%;
  margin-bottom:10px;
}
.footer-socials {
  color:#00a651;
  font-family: "Nunito Sans", sans-serif;
  font-size:16px;
  font-weight:bold;
}
.footer-socials ul {
  list-style-type: none;
  padding-left:0;
  margin-top:15px;
}
.footer-socials a:link {
  color:white;
  font-size:16px;
}
.footer-socials a:visited {
  color:white;
  font-size:16px;
}
.footer-socials a:hover {
  text-decoration:none;
  color:#00a651;
  font-size:16px;
  border-bottom:2px solid #00a651;
}
.footer-socials i {
  width:15px;
  margin-right:5px;
}
.footer-copyrightrow {
  color:white;
  font-family: "Nunito Sans", sans-serif;
  font-size:14px;
  padding-top:30px;
  padding-bottom:30px;
}


/* forms */
input, select, textarea {
  border-color:rgb(240, 240, 240) !important;
  border-radius:0 !important;
}
input.ng-invalid.ng-touched {
  color: red;
  border-color:red !important;
}
input[type="checkbox"].ng-invalid.ng-touched {
  outline:1px solid red !important;
}
textarea.ng-invalid.ng-touched {
  color: red;
  border-color:red !important;
}
select.ng-invalid.ng-touched {
  color: red;
  border-color:red !important;
}
div.ng-invalid {
  color: red;
}
.btn-primary {
  border:0;
  background-color:rgb(0, 166, 81);
  font-family: "Nunito Sans", sans-serif;
  font-size:16px;
  color:white !important;
  border-radius:0;
  padding-left:20px;
  padding-right:20px;
}
.btn-primary:hover {
  background-color:rgb(50, 216, 131);
}
.btn-primary:disabled {
  background-color:rgb(50, 216, 131);
}
.btn-disabled{
  cursor: not-allowed;
}
.mat-button-wrapper{
  color: #358876 !important;
}
.custom-event {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 20px auto -30px !important;
  font-weight: 400;
  font-size: 24px !important;
  background: #337AB7 !important;
  border: #337AB7 !important;
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  .custom-event {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 18px !important;
    margin: 0 auto -30px !important;
  }
}
.link-block {
  position: relative;
  float:left;
  width:240px;
  height:110px;
  line-height:110px;
  margin-right:8px;
  margin-bottom:8px;
  background-color:rgb(0, 166, 81);
  color:white;
  font-family: "Nunito Sans", sans-serif;
  font-size:14px;
  font-weight:bold;
}
.link-block:hover {
  text-decoration:none;
  color:black;
}
.link-block::after {
  content: ">";
  color: white;
  position: absolute;
  right: 10px;
}
.link-block div {
  display:inline-block;
  line-height:24px;
  vertical-align:middle;
}
.link-block-login {
  display: block;
  width:250px;
  height:50px;
  line-height:50px;
  margin-right:8px;
  margin-bottom:8px;
  background-color:rgb(0, 166, 81);
  color:white;
  font-family: "Nunito Sans", sans-serif;
  font-size:14px;
  font-weight:bold;
  text-align:center;
}
.link-block-login:hover {
  text-decoration:none;
  color:black;
}
.link-block-login div {
  display:inline-block;
  line-height:24px;
  vertical-align:middle;
}
@media only screen and (max-width: 767px) {
  .link-block-login {
    margin: auto;
  }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
